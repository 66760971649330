import Footer from "@/components/nav/footer";
import Nav from "@/components/nav/nav";
import { usePage } from "@inertiajs/react";
import { PropsWithChildren, useState } from "react";
import { trackpageVisit } from '@/lib/events'
import { router } from '@inertiajs/react'


router.on('navigate', event => {
    console.log(`Navigated to ${event.detail.page.url}`)
    trackpageVisit(event.detail.page.url)
})

const GeneralLayout = ({ children }: PropsWithChildren<{}>) => {
    const [showingNavigationDropdown, setShowingNavigationDropdown] =
        useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const { auth, appName }: any = usePage().props;

    return (
        <div className="light dark:dark bg-background text-foreground">
            <Nav />
            <main className="">
                {children}
            </main>
            <Footer />
        </div>
    );
    
};
export default GeneralLayout;
