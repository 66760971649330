import React from "react";
import ApplicationLogo from "../ApplicationLogo";
import {
    Navbar,
    NavbarBrand,
    NavbarContent,
    NavbarItem,
    NavbarMenuToggle,
    NavbarMenu,
    NavbarMenuItem,
    Link,
    Button,
    Avatar,
    DropdownItem,
    DropdownTrigger,
    Dropdown,
    DropdownMenu,
} from "@nextui-org/react";
import { usePage, Link as InertiaLink } from "@inertiajs/react";
import LanguageSwitcher from "./language-switcher";

function Nav() {
    const [isMenuOpen, setIsMenuOpen] = React.useState(false);

    const { auth, nav } = usePage<{ auth: any }>().props;

    const menuItems = [
        "Profile",
        "Dashboard",
        "Activity",
        "Analytics",
        "System",
        "Deployments",
        "My Settings",
        "Team Settings",
        "Help & Feedback",
        "Log Out",
    ];

    // console.log("nav", nav)

    return (
        <>
            {/* <pre className="">{JSON.stringify(nav, null, 2)}</pre> */}
            <Navbar isBordered onMenuOpenChange={setIsMenuOpen}>
                <NavbarContent>
                    <NavbarMenuToggle
                        aria-label={isMenuOpen ? "Close menu" : "Open menu"}
                        className="sm:hidden"
                    />
                    <NavbarBrand>
                        <ApplicationLogo className="block h-9 w-auto fill-current text-foreground-800 mr-2" />
                        <Link color="foreground" href="/">
                            <p className="font-bold text-inherit hidden md:block">
                                NextGen LCMS
                            </p>
                        </Link>
                    </NavbarBrand>
                </NavbarContent>

                <NavbarContent
                    className="hidden sm:flex gap-4"
                    justify="center"
                >
                    <NavbarItem>
                        <Link color="foreground" href="/courses">
                            Courses
                        </Link>
                    </NavbarItem>
                    <NavbarItem>
                        <Link color="foreground" href="/">
                            About
                        </Link>
                    </NavbarItem>

                    {/* <NavbarItem isActive>
            <Link color="foreground" href="#" aria-current="page">
              Integrations
            </Link>
          </NavbarItem> */}
                </NavbarContent>
                <NavbarContent justify="end">
                    {auth?.user ? (
                        <Dropdown placement="bottom-end">
                            <DropdownTrigger>
                                <Avatar
                                    isBordered
                                    as="button"
                                    className="transition-transform"
                                    color="secondary"
                                    name={auth?.user?.name}
                                    size="sm"
                                    src={`https://i.pravatar.cc/150?u=${auth?.user?.name}`}
                                />
                            </DropdownTrigger>
                            <DropdownMenu
                                aria-label="Profile Actions"
                                variant="flat"
                            >
                                <DropdownItem
                                    key="profile"
                                    className="h-14 gap-2"
                                >
                                    <p className="font-semibold">
                                        Signed in as
                                    </p>
                                    <p className="font-semibold">
                                        {auth?.user?.email}
                                    </p>
                                </DropdownItem>
                                <DropdownItem key="dashboard" href="/dashboard">
                                    My Dashboard
                                </DropdownItem>

                                <DropdownItem key="logout" color="danger">
                                    <InertiaLink method="post" href="/logout">
                                        Log Out
                                    </InertiaLink>
                                </DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    ) : (
                        <>
                            <NavbarItem className="hidden lg:flex">
                                <Link href="/login">Login</Link>
                            </NavbarItem>
                            <NavbarItem>
                                <Button
                                    as={Link}
                                    color="primary"
                                    href="/register"
                                    variant="flat"
                                >
                                    Sign Up
                                </Button>
                            </NavbarItem>
                        </>
                    )}
                </NavbarContent>
                <LanguageSwitcher />
                <NavbarMenu>
                    {menuItems.map((item, index) => (
                        <NavbarMenuItem key={`${item}-${index}`}>
                            <Link
                                color={
                                    index === 2
                                        ? "primary"
                                        : index === menuItems.length - 1
                                        ? "danger"
                                        : "foreground"
                                }
                                className="w-full"
                                href="#"
                                size="lg"
                            >
                                {item}
                            </Link>
                        </NavbarMenuItem>
                    ))}
                </NavbarMenu>
            </Navbar>
        </>
    );
}

export default Nav;
