import "reactflow/dist/style.css";
import "./bootstrap";
import "../css/app.css";
import "@splidejs/react-splide/css";

import 'klaro/dist/klaro.css'
import '../css/klaro-custom.css'

import { TrackingProvider } from "./context/tracking-context";

import { createRoot } from "react-dom/client";
import { createInertiaApp } from "@inertiajs/react";
import { NextUIProvider } from "@nextui-org/react";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
import { ContentfulLivePreviewProvider } from "@contentful/live-preview/react";
import GeneralLayout from "./Layouts/general-layout";

const appName = import.meta.env.VITE_APP_NAME || "NextGen";

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => {
        const page = resolvePageComponent(
            `./Pages/${name}.tsx`,
            import.meta.glob("./Pages/**/*.tsx")
        );

        page.then((module: any) => {
            // module.default.layout = module.default.layout || GeneralLayout
            module.default.layout =
                module.default.layout ||
                ((module: any) => (
                    <NextUIProvider>
                        <ContentfulLivePreviewProvider locale="en-US">
                            <GeneralLayout children={module} />
                        </ContentfulLivePreviewProvider>
                    </NextUIProvider>
                ));
        });

        return page;
    },
    setup({ el, App, props }) {
        const root = createRoot(el);
        const inertiaProps: any = props?.initialPage?.props;
        root.render(
            <TrackingProvider session_id={inertiaProps?.session_id as any}>
                <App {...props} />
            </TrackingProvider>
        );
    },
    progress: {
        color: "#4B5563",
    },
});
