import { SVGAttributes } from 'react';
import { IoIosSchool } from "react-icons/io";

export default function ApplicationLogo(props: SVGAttributes<SVGElement>) {
    return (
       <div className="">
        <IoIosSchool size={40}/>
       </div>
    );
}
