import React from "react";
import { router } from "@inertiajs/react";
import { Select, SelectItem, Selection } from "@nextui-org/react";
import { MdLanguage } from "react-icons/md";
import { usePage } from "@inertiajs/react";

export const locales = [
    { code: "en-US", label: "English" },
    { code: "de", label: "German" },
    { code: "zh-Hant-TW", label: "Chinese" },
];

const onSelectionChange = (lang: any) => {
    // router.visit(`/lang/${lang?.currentKey}`);
    try {
        window.location.href = `/lang/${lang?.currentKey}`;
    } catch (error) {}
};

const LanguageSwitcher = () => {
    const { locale }: any = usePage().props;

    return (
        <div>
            <Select
                variant="faded"
                color="secondary"
                startContent={<MdLanguage size={30} />}
                selectionMode="single"
                className="w-full min-w-32"
                onSelectionChange={onSelectionChange}
                // defaultSelectedKeys={locale || "en-US"}
                defaultSelectedKeys={[locale || "en-US"]}
                items={locales}
            >
                {locales.map((locale) => (
                    <SelectItem key={locale.code}>{locale.label}</SelectItem>
                ))}
            </Select>
        </div>
    );
};

export default LanguageSwitcher;
